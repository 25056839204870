@import "/src/app/theme/theme.scss";

.icon-button {
  cursor: pointer;
  background: $grey100;
  width: 32px;
  height: 32px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover:not(:disabled) {
    filter: brightness(0.95);
  }
  button {
    cursor: pointer;
    border: none;
    background: none;
  }
}

.account-login {
  position: relative;
  width: 100%;
  border: $border;
  border-radius: $border-radius;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  height: 32px;
  box-sizing: border-box;
  color: #444648;
  cursor: pointer;
  background: $white;
  span {
    margin-left: auto;
    margin-right: auto;
  }
  &:hover:not(:disabled) {
    filter: brightness(0.95);
  }
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  min-height: 30px;
  height: 100%;
  padding: 6px 16px;
  border-radius: 4px;
  line-height: 1;
  transition: 0.15s all ease-in-out;
  box-sizing: border-box;
  outline: none;
  box-shadow: none;
  border: 1px solid transparent;
  background: transparent;
  font-family: "Poppins", sans-serif;
  &.btn {
    &-primary {
      background: $primary700;
      border-color: $primary700;
      color: $white;

      &:hover {
        background-color: $primary900;
        border-color: $primary900;
      }
    }
    &-loadmore {
      background: white;
      color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 18px;
      height: auto;
      font-weight: 600;
      box-shadow: $box-shadow-card;
      transition: all 0.15s ease-in-out;
      margin-left: auto;
      padding: 32px;
      aspect-ratio: 169 / 229;
      &:hover {
        color: $primary700;
        box-shadow: 0 2px 12px #cfd3da;
      }
    }
  }
  &.disabled, &[disabled] {
    opacity: 0.8;
    pointer-events: none !important;
  }
}
