@import "src/app/theme/theme.scss";
@import "/src/app/theme/mixins.scss";

.table-sections {
  display: flex;
}
.table-section {
  height: 100%;
  display: flex;
  align-items: center;
  .table-label {
    cursor: pointer;
    box-sizing: border-box;
    min-width: 32px;
    height: 32px;
    padding: 5px;
    border: 2px solid $grey300;
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    &.circle {
      border-radius: 50%;
    }
    &.loading {
      svg-icon {
        svg {
          @include rotate-animation();
        }
      }
    }
    &.active,
    &:hover {
      background: $primary700;
      border-color: $primary700 !important;
      color: white;
      svg-icon {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
  &.group {
    .table-label {
      border-radius: 0;
      border-right: none;
      border-left: 1px solid $grey300;
      &:first-child {
        border-left: 2px solid $grey300;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
      &:last-child {
        border-right: 2px solid $grey300;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}
