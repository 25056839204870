@import "/src/app/theme/theme.scss";

html,
body {
  height: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $grey100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey600;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey700;
}

/* non-webkit scrollbar */
body, html {
  scrollbar-width: thin;
  scrollbar-color: $grey600 $grey100;
}

.text {
  &-center {
    text-align: center;
  }
}
