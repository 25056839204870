@import "/src/app/theme/theme.scss";

.list-box {
  background: white;
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  box-shadow: $box-shadow-card;
  display: flex;
  flex-direction: column;
}

.profile-box {
  background: $grey100;
  flex: 1;
  margin: 16px;
  padding: 16px;
  border-radius: $border-radius;
}

.details-box {
  .details-grid {
    display: grid;
    grid-template-columns: 1fr 2.2fr 0.8fr;
    column-gap: 16px;
    .details-column {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & > * {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .details-tile {
        background: white;
        width: 100%;
        height: auto;
        border-radius: $border-radius;
        box-shadow: $box-shadow-card;
        .details-tile-header {
          color: $grey700;
          padding: 10px;
          border-bottom: $border;
        }
        .details-tile-content {
          padding: 16px;
          display: flex;
          gap: 16px;
          justify-content: space-between;
          align-items: center;
        &.column {
          flex-flow: column;
          align-items: start;
        }
          .details-tile-data {
            display: flex;
            flex-direction: column;
            margin-right: auto;
            .details-tile-title {
            }
            .details-tile-subtitle {
              color: $grey700;
            }
          }
          .details-tile-icon-buttons {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          &.no-padding {
            padding: 0;
          }
        }
        .details-tile-properties {
          width: 100%;
          display: flex;
          flex-direction: column;
          .details-tile-property {
            display: flex;
            justify-content: space-between;
            .details-tile-property-key {
              color: $grey700;
            }
            &.bold {
              .details-tile-property-key {
                font-size: 1.2rem;
              }
              .details-tile-property-value {
                font-weight: bold;
                font-size: 1.2rem;
              }
            }
          }
        }
        .details-tile-action {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          box-sizing: border-box;
          &-label {
            color: $grey800;
            font-size: 1.2rem;
          }
          &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-end;
            gap: 8px;
          }
        }
        .details-tile-table {
          min-height: 400px;
        }
        .details-tile-gallery {
          width: 100%;
        }
        .details-tile-button {
          padding: 16px;
        }
      }
      .details-links {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .details-link {
          cursor: pointer;
          box-sizing: border-box;
          background: white;
          width: calc(50% - 10px);
          height: auto;
          border-radius: $border-radius;
          box-shadow: $box-shadow-card;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 5px;
          &.disabled {
            cursor: auto;
            opacity: 0.6;
          }
        }
      }
      .details-tile-wallet {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          align-self: flex-start;
        }
        .values {
          display: flex;
          flex-direction: column;
          .value {
            font-weight: bold;
            font-size: 1.2rem;
          }
          .change {
            .change-value {
              &.positive {
                color: $green700;
              }
              &.negative {
                color: $red700;
              }
            }
            .change-text {
              font-size: 0.8rem;
              color: $grey700;
              margin-left: 5px;
            }
          }
        }
      }
      .details-tile-chart {
        padding: 10px 0;
      }
      .details-rates {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .details-rate {
          box-sizing: border-box;
          background: white;
          width: calc(33.33% - 10px);
          height: auto;
          font-size: 10px;
          border-radius: $border-radius;
          box-shadow: $box-shadow-card;
          padding: 10px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          &.disabled {
            cursor: auto;
            opacity: 0.6;
          }
          .percentage-change {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

.details-tile-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.small {
    height: 200px;
  }
  &.mid {
    height: 400px;
  }
  &.large {
    height: 600px;
  }
}

.details-tile-list {
  display: flex;
  flex-direction: column;
  .details-tile-list-item {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border;
    .details-tile-list-item-photo {
      height: 50px;
      width: 50px;
      background: $grey300;
      margin-right: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        display: block;
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
      }
    }
    .details-tile-list-item-data {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      .details-tile-list-item-title {
      }
      .details-tile-list-item-subtitle {
        font-style: italic;
      }
    }
    .details-tile-list-item-values {
      display: flex;
      .details-tile-list-item-value {
        padding: 10px;
        width: 100px;
        display: flex;
        justify-content: center;
      }
      .details-tile-list-item-percentage-change {
        padding: 10px;
        width: 100px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.percentage-change {
  font-weight: bold;
  &.positive {
    color: $green700;
  }
  &.negative {
    color: $red700;
  }
}

.details-page-box {
  padding: 0 50px;
}
