@import "src/app/theme/theme.scss";

// checkbox
mat-checkbox {
  .mdc-checkbox__background {
    border-radius: 4px;
    display: flex;
    align-items: center;
    .mdc-checkbox__checkmark {
      width: 70%;
      margin: auto;
      display: block;
    }
  }
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)
    ~ .mdc-checkbox__background {
    border-color: $grey300 !important;
  }
  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    border-color: $primary700 !important;
  }

  .mdc-checkbox__ripple {
    opacity: 0 !important;
  }
}

// input
.field-wrapper {
  position: relative;
  margin-bottom: 8px;
  .field {
    display: flex;
    flex-direction: column;
    height: $forms-atom-height;
    justify-content: flex-end;
    &.textarea {
      height: auto;
    }
    &:not(.valueChosen) {
      input {
        &:not(:placeholder-shown) {
          & ~ .input-add-btn {
            display: flex;
          }
        }
      }
    }
  }
  input,
  .mat-mdc-select-value,
  textarea {
    flex: 1;
    background-color: $white;
    text-align: left;
    width: calc(100% - 2px);
    box-sizing: border-box;
    font-family: inherit;
    font-size: $forms-atom-font;
    border: none;
    outline: none;
    padding: 0;
    position: relative;
    border-bottom: $border;
    &:read-only {
      cursor: default;
    }
  }
  textarea {
    resize: none;
  }
  .input-clear {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  .input-add-btn {
    display: none;
    position: absolute;
    left: auto;
    bottom: auto;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    min-height: 20px;
    height: 20px;
    font-size: 11px;
    line-height: 1;
    padding: 2px 6px;
    gap: 4px;
    font-weight: 200;
    svg-icon {
      max-width: 12px;
      overflow: hidden;
    }
  }
  .error {
    height: $mat-error-height;
  }
}

label {
  display: flex;
  text-align: left;
  color: $grey700;
  font-size: $forms-atom-font-label;
  .required {
    padding-left: 2px;
    color: $red100;
  }
}

.mat-mdc-select-arrow-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: auto !important;
}

.mat-mdc-autocomplete-panel {
  .spinner-option {
    opacity: 1 !important;
    & > span {
      display: block;
      margin: 0 auto;
    }
    .spinner {
      margin: 0;
    }
  }
}


