@import "/src/app/theme/theme.scss";

mat-tab-group {
  flex: 1;
  max-height: 100%;
  .mat-mdc-tab-body-wrapper {
    flex: 1;
    .mat-mdc-tab-body-content {
      display: flex;
      flex-direction: column;
    }
  }
}

.list-tabs {
  .mat-mdc-tab-labels {
    padding: 0 20px;
    background: $grey100;
  }
  .mdc-tab {
    height: $forms-atom-height !important;
    &.mdc-tab--active {
      .mdc-tab__content {
        .mdc-tab__text-label {
          color: inherit;
        }
      }
    }
  }
}

.form-tabs {
  .mdc-tab {
    background: $grey100;
    height: $forms-atom-height !important;
    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    &.mdc-tab--active {
      background: $primary700;
      .mdc-tab__content {
        .mdc-tab__text-label {
          color: $white;
        }
      }
      .mdc-tab-indicator {
        .mdc-tab-indicator__content {
          border: none;
        }
      }
    }
  }
}

.mat-drawer-container {
  flex: 1;
}

.mat-drawer-content {
  display: flex !important;
  flex-direction: column;
}

.mat-drawer-backdrop {
  background: none !important;
}

.mat-drawer {
  box-shadow: $box-shadow-card !important;
}

.mat-calendar-body-selected {
  color: $white !important;
}
