@import "src/app/theme/theme.scss";

.tiles-grid {
  display: grid;
  grid-template-columns: 1fr 2.2fr 0.8fr;
  column-gap: 16px;
  @media all and (max-width: 930px) {
    display: flex;
    flex-flow: column;
    gap: 16px;
  }
  .tiles-column {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & > * {
      margin-bottom: 16px;
      &.hidden {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .tile {
      box-sizing: border-box;
      background: white;
      width: 100%;
      height: auto;
      border-radius: $border-radius;
      box-shadow: $box-shadow-card;
      &.padding {
        padding: 16px;
      }
      .tile-header {
        color: $grey700;
        padding: 10px;
        border-bottom: $border;
      }
    }
  }
}
