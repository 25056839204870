@import "/src/app/theme/theme.scss";

.mat-mdc-menu-panel {
  margin-top: 16px !important;
  position: relative !important;
  overflow: visible !important;
  min-height: auto;
  &::before {
    position: absolute;
    top: -4px;
    left: 10px;
    content: "";
    height: 12px;
    width: 12px;
    background-color: #fff;
    transform: rotate(45deg);
  }
}

.dropdown {
  padding: 10px !important;
}

.dropdown-item {
  cursor: pointer;
  min-width: 200px;
  background: $grey100;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
  &.column {
    padding: 0 16px;
    img {
      display: block;
      margin: auto;
    }
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: $box-shadow-card;
  opacity: 100;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  display: flex !important;
  flex-direction: column !important;
  overflow-y: unset;
}

.dialog {
  height: 100%;
  .dialog-header {
    color: $grey700;
    padding: 10px;
    border-bottom: $border;
    background-color: white;
    z-index: 1;
    box-sizing: border-box;
    height: 38px;
    .dialog-close {
      position: absolute;
      top: 9px;
      right: 9px;
      cursor: pointer;
    }
  }
  .dialog-content {
    height: 100%;
    padding: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
    box-sizing: border-box;
    .dialog-group {
      .dialog-group-header {
        margin-bottom: 10px;
        .dialog-group-title {
          font-size: 1.3rem;
          font-weight: bold;
        }
        .dialog-group-subtitle {
          font-size: 1.2rem;
        }
      }
      .dialog-group-items {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .dialog-group-item {
        box-sizing: border-box;
        flex-basis: calc(50% - 8px);
      }
    }
    .dialog-checkbox {
      padding-right: 40px;
    }
    .dialog-image {
      border-radius: $border-radius;
      margin: 20px 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      height: 300px;
    }
    .dialog-tiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &.grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px;
      }
    }
    .dialog-tile {
      padding: 10px;
      border: $border;
      border-radius: $border-radius;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 20px;
      &.label {
        cursor: pointer;
      }
      &.margin-top {
        margin-top: 20px;
      }
      .dialog-tile-text {
        display: flex;
        flex-direction: column;
      }
      .dialog-tile-checkbox {
        flex-shrink: 0;
        border: 1px solid black;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.checked {
          background-color: $primary700;
          border-color: $primary700;
          &::after {
            content: "";
            width: 12px;
            height: 12px;
            background: url(/assets/icons/check.svg) no-repeat center center;
          }
        }
      }
      .dialog-tile-properties {
        width: 100%;
        display: flex;
        flex-direction: column;
        .dialog-tile-property {
          display: flex;
          justify-content: space-between;
          .dialog-tile-property-key {
            color: $grey700;
          }
          &.bold {
            .dialog-tile-property-key {
              font-size: 1.2rem;
            }
            .dialog-tile-property-value {
              font-size: 1.2rem;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  form {
    height: 100%;
  }

  .dialog-body {
    height: calc(100% - 38px - 65px);
    max-height: calc(90vh - 38px - 65px);
    overflow: auto;
  }

  .dialog-actions {
    height: 65px;
  }

  .dialog-buttons-container {
    padding: 16px;
    border-top: $border;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
    box-sizing: border-box;
    .dialog-buttons {
      width: 80%;
      display: flex;
      align-items: center;
      gap: 16px;
      button {
        height: 32px;
      }
    }
  }
}

.mat-mdc-option {
  min-height: $forms-atom-height !important;
}
