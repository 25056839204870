@import "src/app/theme/theme.scss";

.lb-nav {
    a.lb-next, a.lb-prev {
        opacity: 1 !important;
        position: absolute;
    }
    a.lb-prev {
        padding: 10px;
        background: none !important;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            background: url("/assets/icons/arrow-left_white.svg") no-repeat left 50% !important;
            display: inline-block;
            width: 20px;
            height: 20px;
        }
    }
    a.lb-next {
        padding: 10px;
        background: none !important;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            background: url("/assets/icons/arrow-right_white.svg") no-repeat right 50% !important;
            display: inline-block;
            width: 20px;
            height: 20px;
        }
    }
}

.lb-data {
    .lb-caption {
        font-weight: normal !important;
    }
    .lb-close {
        background: url('/assets/icons/x.svg') center no-repeat, $grey100 !important;
        border-radius: $border-radius;
    }
}