@import "src/app/theme/theme.scss";

.layout {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  .header {
    height: 80px;
    background: white;
  }
  .main {
    height: calc(100vh - 80px - 60px);
    display: flex;
    flex-direction: column;
    background: $grey100;
    .content {
      height: calc(100% - 40px);
      padding: 20px;
    }
  }
  .footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}
