@import url("https://fonts.googleapis.com/css?family=Poppins");

$primary700: #2e4395;
$primary900: #192873;

$grey100: #f7f7f7;
$grey300: #e4e4e4;
$grey500: #9ba0a7;
$grey600: #72777d;
$grey700: #5e6369;
$grey800: #3f444a;

$orange800: #fca80c;

$green300: #40dc7e;
$green700: #2e9546;

$red100: #f44336;
$red700: #db0b14;

$white: #fff;

$border: 1px solid $grey300;
$border-radius: 4px;
$box-shadow-card: 0px 2px 12px #dfe3eb;
$box-shadow-active: 0px 2px 12px #9ba0a7;
$forms-atom-height: 42px;
$fomrs-atom-input-height: 24px;
$forms-atom-font: 1.15rem;
$forms-atom-font-label: 1rem;

$mat-error-height: 1.5rem;
